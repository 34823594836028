import React from 'react'
import Home from './components/pages/Home'
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Services from './components/pages/Services'
import Products from './components/pages/Products'
import SignUp from './components/pages/Appointment'
import Footer from './components/Footer';
import Team from './components/pages/Team'
import Appointment from './components/pages/Appointment';




function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/services' exact element={<Services />} />
          <Route path='/team' exact element={<Team />} />
          {/* products will be added later
          <Route path='/products' exact element={<Products />} /> */}
          <Route path='/appointment' exact element={<Appointment />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
