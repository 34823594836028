import React from "react";
import './Team.css'

export default function Team() {
    return (
        <>
            <div class="team">
                <div class="team-title">
                    <h1>Meet our Team</h1>
                </div>
                {/* <h1 class="employee-category">Providers</h1> */}
                <div class="members">
                    <div class="member">
                        <div class="image-description">
                            <img class="pic" src="/images/Paul.jpeg" alt="paul rockley"/>
                            <div class="description">Paul F. Rockley MD</div>
                        </div>
                        <p>Paul Rockley, MD received his medical degree from The Chicago Medical School.
                            He completed his dermatology residency at Washington Hospital Center in Washington, DC, where he also served as chief resident.
                            Dr. Rockley is a board certified dermatologist who has treated over 1 million patients with skin, hair and nail conditions in South Florida over the last 30 years.
                            <br /><br />
                            Dr. Rockley is an expert in medical, surgical and cosmetic dermatology.
                            He specializes in facial rejuvenation and hair regeneration using laser and light technology, Botox, fillers, chemical peels and platelet rich plasma (PRP) treatments.
                            <br /><br />
                            Dr. Rockley’s intense passion for correlating clinical dermatology and skin pathology led to the construction of an on-site full service dermatopathology laboratory.
                            This laboratory has enabled him to vertically integrate his operations to provide the highest quality of skin care while minimizing patient medical expenses.
                            <br /><br />
                            Dr. Rockley served as principal and co-investigator in research studies which were funded by National Institute of Health (NIH) research grants.
                            These studies lead to several publications in peer reviewed journals and chapters in books which were enthusiastically received by physicians around the world.
                        </p>
                    </div>
                    <div class="member">
                        <div class="image-description">
                            <img class="pic" src="/images/yvana_new.jpg" alt="yvana cespedes"/>
                            <div class="description">Yvana Cespedes MD</div>
                        </div>
                        <p>Dr. Cespedes is a board certified physician in Anatomic Pathology, Clinical Pathology and Dermatopathology.
                            Dr. Cespedes graduated medical school at San Marcos University in Lima, Peru.
                            Dr. Cespedes completed a five year residency in Anatomic and Clinical Pathology at Washington Hospital Center, Washington D.C. and Mount Sinai Medical Center in Miami Beach, FL, where she also served as chief resident.
                            Posteriorly, Dr. Cespedes became a Dermatopathology fellow at the Dr. Phillip Frost Department of Dermatology, University of Miami School of Medicine.
                            <br /><br />
                            Dr. Cespedes has been in practice for over 20 years working in national leading laboratories in the Dermatopathology field including: Cleveland Clinic in Florida, Labcorp, Aurora Diagnostics and Sonic Health Care.
                            Dr. Cespedes’ dermatopathology expertise has been an asset in the correlation between the clinical findings observed in patients at the time of their evaluation and the histologic findings observed in their skin biopsies under the microscope.
                            Dr. Cespedes is also an important member of the Mohs team for the treatment of skin cancer. Dr. Cespedes’ other areas of interest include diagnosis and treatment of hair loss, aesthetic skin rejuvenation using Red Light and Platelet Rich Plasma (PRP) treatment and adjuvant oral supplementation of non-hormonal natural supplements.
                            <br /><br />
                            Dr. Cespedes loves traveling with her family and she finds joy being a board member of Peruvian American Medical Society (PAMS), a humanistic organization that focuses on providing human and material resources that reach remote, medically-underserved communities of Peru.

                        </p>
                    </div>
                    <div class="member">
                        <div class="image-description">
                            <img class="pic" src="/images/Manuela.jpeg" alt="manuela rosero"/>
                            <div class="description">Manuela Rosero PA</div>
                        </div>
                        <p>Manuela Rosero is a Board Certified Physician Assistant. 
                            She earned her certification by the National Commission on Certification of Physician Assistant. 
                            <br /><br />
                            Manuela completed her Master of Medical Science in Physician Assistant studies at Nova Southeastern University.
                            Prior to that, she obtained her B.S. in Molecular, Cellular, and Developmental Biology from University of Washington in Seattle, WA. 
                            <br /><br />
                            During her free time, Manuela enjoys running, dancing, playing the guitar and spending time with her family.
                        </p>    
                    </div>
                </div>

            </div>
        </>
    )
}