import React from 'react'
import {Button} from './Button'
import './HeroSection.css'
import '../App.css'

function HeroSection() {
  return (
    <div class='hero-container'>
        <video src='/videos/progress_30secs_v2.mp4' autoPlay loop muted webkit-playsinline playsInline/>
        {/* <h1>BEST SKINCARE IN SOUTH FLORIDA</h1> */}
        <div class="hero-btns">
            <Button 
              class='btns' 
              buttonStyle='btn--white'
              buttonSize='btn--large'
            >
                REQUEST APPOINTMENT
            </Button>
        </div>

    </div>
  )
}

export default HeroSection;