import React from "react";
// import '../../App.css'
import './Services.css'


export default function Services() {
    return (
        <>
            <div class="services">
                <div class="services-title ">
                    <h1>Services</h1>
                </div>
                <div class="content">
                    <div class="service-list left">
                        <h2>Clinical Dermatology</h2>
                        <div class="extended-list">
                            <ul class="list ">
                                <li class="service">
                                    Acne
                                </li>
                                <li class="service">
                                    Actinic Keratosis
                                </li>
                                <li class="service">
                                    Age Spots
                                </li>
                                <li class="service">
                                    Allergic Dermatitis
                                </li>
                                <li class="service">
                                    Atopic Dermatitis
                                </li>
                                <li class="service cancer">
                                    Basal Cell Carcinoma
                                </li>
                                <li class="service">
                                    Cold Sore
                                </li>
                                <li class="service">
                                    Contact Dermatitis
                                </li>
                                <li class="service">
                                    Cysts
                                </li>
                                <li class="service">
                                    Fungal Infection
                                </li>
                                <li class="service">
                                    Hair Loss
                                </li>
                                <li class="service">
                                    Herpes
                                </li>
                                <li class="service">
                                    Hyperpigmentation
                                </li>
                                <li class="service">
                                    Keloid
                                </li>
                                <li class="service">
                                    Keratosis Pilaris
                                </li>



                            </ul>
                            <ul class="list right-list">
                                <li class="service">
                                    Lupus
                                </li>
                                <li class="service cancer">
                                    Melanoma
                                </li>
                                <li class="service">
                                    Melasma
                                </li>
                                <li class="service">
                                    Moles
                                </li>
                                <li class="service">
                                    Molluscum Contagiosum
                                </li>
                                <li class="service">
                                    Psoriasis
                                </li>
                                <li class="service">
                                    Rosacea
                                </li>
                                <li class="service">
                                    Seborrheic Dermatitis
                                </li>
                                <li class="service">
                                    Seborrheic Keratosis
                                </li>
                                <li class="service">
                                    Skin Tags
                                </li>
                                <li class="service cancer">
                                    Squamous Cell Carcinoma
                                </li>
                                <li class="service">
                                    Varicella-Zoster Virus
                                </li>
                                <li class="service">
                                    Vitiligo
                                </li>
                                <li class="service">
                                    Warts
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="service-list right">
                        <h2>Cosmetic Dermatology</h2>
                        <ul class="list ">
                            <li class="service">
                                Botox
                            </li>
                            <li class="service">
                                Chemical Peel
                            </li>
                            <li class="service">
                                Fillers
                            </li>
                            <li class="service">
                                Hair Removal
                            </li>
                            <li class="service">
                                Laser
                            </li>
                            <li class="service">
                                Mohs Surgery
                            </li>
                            <li class="service">
                                Non-Hormonal Supplement Treatment
                            </li>
                            <li class="service">
                                P-Shot
                            </li>
                            <li class="service">
                                Platelet-Rich Plasma (PRP)
                            </li>
                            <li class="service">
                                Red Light
                            </li>
                            <li class="service">
                                Tattoo Removal
                            </li>
                            <li class="service">
                                V-Shot
                            </li>



                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}