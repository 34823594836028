import React, { useState } from "react";
import './Appointment.css'
import { Button } from '../Button'

export default function Appointment() {

    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        insuranceProvider:"",
        insuranceNum: "",
        phone: "",
        email: "",
        newPatient: "yes",
        availableDateTimes: "",
    })

    const [submitted, setSubmitted] = useState(false)


    const [success, setSuccess] = useState(false)

    const handleFirstNameInputChange = (event) => {
        setValues({ ...values, firstName: event.target.value })
    }

    const handleLastNameInputChange = (event) => {
        setValues({ ...values, lastName: event.target.value })
    }

    const handleDateOfBirthInputChange = (event) => {
        setValues({ ...values, dateOfBirth: event.target.value })
    }

    const handleInsuranceProviderChange = (event) => {
        setValues({ ...values, insuranceProvider: event.target.value })
    }

    const handleInsuranceNumInputChange = (event) => {
        setValues({ ...values, insuranceNum: event.target.value })
    }

    const handlehonePInputChange = (event) => {
        setValues({ ...values, phone: event.target.value })
    }

    const handleEmailInputChange = (event) => {
        setValues({ ...values, email: event.target.value })
    }

    const handleNewPatientInputChange = (event) => {
        setValues({ ...values, newPatient: event.target.value })
    }

    const handleAvailableDateTimesInputChange = (event) => {
        setValues({ ...values, availableDateTimes: event.target.value })
    }



    async function postData(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(data)
        });
        return response.json();
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (values.firstName && values.lastName && values.dateOfBirth &&
            values.insuranceProvider && values.insuranceNum && values.phone && values.email) {
            postData("https://jmuc23o5t5.execute-api.us-east-1.amazonaws.com/sendAppointmentRequest", values)
            setSuccess(true)

        }
        setSubmitted(true)
    }


    return (
        <>
            <div class="appointment">
                <div class="appointment-title">
                    <h1>Request Appointment</h1>
                </div>

                <div class="form-container">

                    <form class="appointment-form" onSubmit={handleSubmit} >
                        {submitted && !values.firstName ? <span class="error-message">Please enter a first name</span> : null}
                        <div class="input-field">
                            <label for="firstName">First Name: </label>
                            <input
                                value={values.firstName}
                                onChange={handleFirstNameInputChange}
                                class="form-field"
                                name="firstName"
                                disabled={success} />

                        </div>
                        {submitted && !values.lastName ? <span class="error-message">Please enter a last name</span> : null}
                        <div class="input-field">
                            <label for="lastName">Last Name: </label>
                            <input
                                value={values.lastName}
                                onChange={handleLastNameInputChange}
                                class="form-field"
                                name="lastName" 
                                disabled={success}/>

                        </div>
                        {submitted && !values.dateOfBirth ? <span class="error-message">Please enter a date of birth</span> : null}
                        <div class="input-field">
                            <label for="firstName">Date of Birth: </label>
                            <input
                                type="date"
                                value={values.dateOfBirth}
                                onChange={handleDateOfBirthInputChange}
                                class="form-field"
                                name="dateOfBirth" 
                                disabled={success}/>

                        </div>
                        {submitted && !values.insuranceProvider ? <span class="error-message">Please enter an Insurance Provider</span> : null}
                        <div class="input-field">
                            <label for="insuranceProvider">Insurance Provider: </label>
                            <input
                                value={values.insuranceProvider}
                                onChange={handleInsuranceProviderChange}
                                class="form-field"
                                name="insuranceProvider" 
                                disabled={success}/>

                        </div>
                        {submitted && !values.insuranceNum ? <span class="error-message">Please enter an Insurance ID number</span> : null}
                        <div class="input-field">
                            <label for="insuranceNum">Insurance ID #: </label>
                            <input
                                value={values.insuranceNum}
                                onChange={handleInsuranceNumInputChange}
                                class="form-field"
                                name="insuranceNum" 
                                disabled={success}/>

                        </div>
                        {submitted && !values.email ? <span class="error-message">Please enter a phone number</span> : null}
                        <div class="input-field">
                            <label for="phone">Phone #: </label>
                            <input
                                value={values.phone}
                                onChange={handlehonePInputChange}
                                class="form-field"
                                name="phone" 
                                disabled={success}/>

                        </div>
                        {submitted && !values.email ? <span class="error-message">Please enter an email</span> : null}
                        <div class="input-field">
                            <label for="email">Email: </label>
                            <input
                                value={values.email}
                                onChange={handleEmailInputChange}
                                class="form-field"
                                name="email" 
                                disabled={success}/>
                        </div>

                        <div class="input-field">
                            <label for="newPatient">New Patient? </label>
                            <select
                                value={values.newPatient}
                                onChange={handleNewPatientInputChange}
                                class="form-field"
                                name="newPatient"
                                disabled={success}>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>

                        <label class="area-label" for="availableDateTimes">Dates and times you are available for an appointment: </label>

                        <div class="input-field">
                            <textarea
                                value={values.availableDateTimes}
                                onChange={handleAvailableDateTimesInputChange}
                                class="form-field"
                                name="availableDateTimes"
                                cols="35"
                                rows="5"
                                disabled={success}
                            ></textarea>


                        </div>
                        {/* <Button
                            class='btns'
                            buttonStyle='btn--black'
                            buttonSize='btn--large'
                        >
                            Submit
                        </Button> */}
                        <button class="submit-button" type="submit" disabled={success}>
                            Submit
                        </button>


                    </form>


                </div>
                {success ? <div class="success-message">Success! We will get back to you shortly!</div> : null}

            </div>
        </>
    )
}