import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleToggle = () => setNavbarOpen(!navbarOpen);
  const closeMobileMenu = () => setNavbarOpen(false);

  const checkNarrow = () => {
    if (window.innerWidth > 1500) {
      closeMobileMenu();
    }
  };


  


  window.addEventListener('resize', checkNarrow);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>

          <div class='left-side'>
            <Link to='/' className='navbar-title' onClick={closeMobileMenu}>
              Paul F. Rockley MD
            </Link>

            <Link to='/' className='specialty' onClick={closeMobileMenu}>
              Dermatology
            </Link>
          </div>

          <div class="center-side">
            <div className="menu-icon" onClick={handleToggle}>
              <i className={navbarOpen ? 'fas fa-times' : 'fas fa-bars fa-5x'} />
            </div>
            <div class={navbarOpen ? "showMenu" : "wide"}>
              <ul className='nav-menu'>
                <li className='nav-item'>
                  <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                    Home
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/services'
                    className='nav-links'
                    onClick={closeMobileMenu}
                  >
                    Services
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/team'
                    className='nav-links'
                    onClick={closeMobileMenu}
                  >
                    Team
                  </Link>
                </li>
                {/* Products will be added later
                
                <li className='nav-item'>
                  <Link
                    to='/products'
                    className='nav-links'
                    onClick={closeMobileMenu}
                  >
                    Products
                  </Link>
                </li> */}
                <li className='nav-item'>
                  <Link
                    to='/appointment'
                    className='nav-links'
                    onClick={closeMobileMenu}
                  >
                    Appointment
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div class='right-side'>
            <div class='telephone-container'>
              <a class="phone-number" href="tel:3059407766">
                <i class="fas fa-phone"></i>
                <div class="hide phone-text">(305) - 940 - 7766</div>
              </a>

            </div>
            <div class='map-container'>
              <a class="address" href="https://goo.gl/maps/SZi5mafNWCnKWdRB8">
                <i class="fa fa-map-marker"></i>
                <div class="hide address-text">17101 NE 19th Ave #101 <br/>North Miami Beach, FL 33162</div></a>
            </div>


          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;